export const w = {
  2024: {
    "s": {
      "deduction": 13850,
      "brackets": {
        "0":       { "p": 0.1,  "s": 0.0 },
        "11600":   { "p": 0.12, "s": 1160.0 },
        "47150":   { "p": 0.22, "s": 5426.0 },
        "100525":  { "p": 0.24, "s": 17168.5 },
        "191950":  { "p": 0.32, "s": 39110.5 },
        "243725":  { "p": 0.35, "s": 55686.5 },
        "609350":  { "p": 0.37, "s": 183655.25 }
      }
    },
    "m": {
      "deduction": 27700,
      "brackets": {
        "0":       { "p": 0.1,  "s": 0.0 },
        "23200":   { "p": 0.12, "s": 2320.0 },
        "94300":   { "p": 0.22, "s": 10852.0 },
        "201050":  { "p": 0.24, "s": 34337.0 },
        "383900":  { "p": 0.32, "s": 78221.0 },
        "487450":  { "p": 0.35, "s": 111357.0 },
        "731200":  { "p": 0.37, "s": 196669.5 }
      }
    }
  },
  2025: {
    s: {
      "deduction": 11925,
      "brackets": {
        "0": { "p": 0.1, "s": 0.0 },
        "11925": { "p": 0.12, "s": 1192.5 },
        "48475": { "p": 0.22, "s": 5578.5 },
        "103350": { "p": 0.24, "s": 17651.0 },
        "197300": { "p": 0.32, "s": 40199.0 },
        "250525": { "p": 0.35, "s": 57231.0 },
        "626350": { "p": 0.37, "s": 188769.75 }
      }
    },
    m: {
      "deduction": 23850,
      "brackets": {
        "0": { "p": 0.1, "s": 0.0 },
        "23850": { "p": 0.12, "s": 2385 },
        "96950": { "p": 0.22, "s": 11157 },
        "206700": { "p": 0.24, "s": 35302 },
        "394600": { "p": 0.32, "s": 80398 },
        "501050": { "p": 0.35, "s": 114462 },
        "751600": { "p": 0.37, "s": 202154.5 }
      }
  }},
};

export const s = {
  CA: {
    2024: {
      s: {
        deduction: 5000,
        brackets: {
          0: {
            p: 0.01,
            s: 0.0
          },
          10000: {
            p: 0.02,
            s: 100.0
          },
          25000: {
            p: 0.04,
            s: 400.0
          },
          40000: {
            p: 0.06,
            s: 1000.0
          },
          60000: {
            p: 0.08,
            s: 2200.0
          },
          100000: {
            p: 0.093,
            s: 5980.0
          },
          300000: {
            p: 0.103,
            s: 22060.0
          },
          500000: {
            p: 0.113,
            s: 44660.0
          },
          1000000: {
            p: 0.123,
            s: 101160.0
          },
          1500000: {
            p: 0.133,
            s: 164660.0
          }
        }
      },
      m: {
        deduction: 10000,
        brackets: {
          0: {
            p: 0.01,
            s: 0.0
          },
          20000: {
            p: 0.02,
            s: 200.0
          },
          50000: {
            p: 0.04,
            s: 800.0
          },
          80000: {
            p: 0.06,
            s: 2000.0
          },
          120000: {
            p: 0.08,
            s: 4400.0
          },
          200000: {
            p: 0.093,
            s: 11960.0
          },
          600000: {
            p: 0.103,
            s: 44120.0
          },
          1000000: {
            p: 0.113,
            s: 89320.0
          },
          1500000: {
            p: 0.123,
            s: 202820.0
          },
          2000000: {
            p: 0.133,
            s: 339320.0
          }
        }
      }
    },
    2025: {
      s: {
        deduction: 6000,
        brackets: {
          0: {
            p: 0.01,
            s: 0.0
          },
          15000: {
            p: 0.02,
            s: 150.0
          },
          35000: {
            p: 0.04,
            s: 550.0
          },
          60000: {
            p: 0.06,
            s: 1550.0
          },
          90000: {
            p: 0.08,
            s: 3550.0
          },
          150000: {
            p: 0.093,
            s: 9190.0
          },
          300000: {
            p: 0.103,
            s: 21790.0
          },
          500000: {
            p: 0.113,
            s: 44390.0
          },
          1000000: {
            p: 0.123,
            s: 100890.0
          },
          1500000: {
            p: 0.133,
            s: 164390.0
          }
        }
      },
      m: {
        deduction: 12000,
        brackets: {
          0: {
            p: 0.01,
            s: 0.0
          },
          30000: {
            p: 0.02,
            s: 300.0
          },
          70000: {
            p: 0.04,
            s: 1100.0
          },
          120000: {
            p: 0.06,
            s: 3100.0
          },
          180000: {
            p: 0.08,
            s: 7100.0
          },
          300000: {
            p: 0.093,
            s: 18340.0
          },
          600000: {
            p: 0.103,
            s: 43640.0
          },
          1000000: {
            p: 0.113,
            s: 88240.0
          },
          1500000: {
            p: 0.123,
            s: 202740.0
          },
          2000000: {
            p: 0.133,
            s: 339240.0
          }
        }
      }
    }
  },
  TX: {
    2024: {
      s: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
      m: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
    },
    2025: {
      s: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
      m: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
    },
  },
  WA: {
    2023: {
      s: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        }
      },
      m: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
    },
    2024: {
      s: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        }
      },
      m: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
    },
    2025: {
      s: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        }
      },
      m: {
        deduction: 0,
        brackets: {
          0: {
            p: 0.0,
            s: 0.0,
          },
          1: {
            p: 0.0,
            s: 0.0,
          },
        },
      },
    },
  },
  NY: {
    2024: {
      s: {
        deduction: 8000,
        brackets: {
          0: {
            p: 0.04,
            s: 0.0,
          },
          8500: {
            p: 0.045,
            s: 340.0,
          },
          11700: {
            p: 0.0525,
            s: 484.0,
          },
          13900: {
            p: 0.055,
            s: 599.5,
          },
          80650: {
            p: 0.06,
            s: 4270.75,
          },
          215400: {
            p: 0.0684,
            s: 12355.75,
          },
          1077550: {
            p: 0.0965,
            s: 71413.0199,
          },
          5000000: {
            p: 0.103,
            s: 449929.4499,
          },
          25000000: {
            p: 0.109,
            s: 2509929.45,
          }
        }
      },
      m: {
        deduction: 16050,
        brackets: {
          0: {
            p: 0.04,
            s: 0.0,
          },
          17150: {
            p: 0.045,
            s: 686.0,
          },
          23600: {
            p: 0.0525,
            s: 976.25,
          },
          27900: {
            p: 0.055,
            s: 1202.0,
          },
          161550: {
            p: 0.06,
            s: 8552.75,
          },
          323200: {
            p: 0.0684,
            s: 18251.75,
          },
          2155350: {
            p: 0.0965,
            s: 143754.02,
          },
          5000000: {
            p: 0.103,
            s: 418262.75,
          },
          25000000: {
            p: 0.109,
            s: 2478262.75,
          },
        },
      }
    },
    2025: {
      s: {
        deduction: 8000,
        brackets: {
          0: {
            p: 0.04,
            s: 0.0,
          },
          8500: {
            p: 0.045,
            s: 340.0,
          },
          11700: {
            p: 0.0525,
            s: 484.0,
          },
          13900: {
            p: 0.055,
            s: 599.5,
          },
          80650: {
            p: 0.06,
            s: 4270.75,
          },
          215400: {
            p: 0.0684,
            s: 12355.75,
          },
          1077550: {
            p: 0.0965,
            s: 71413.0199,
          },
          5000000: {
            p: 0.103,
            s: 449929.4499,
          },
          25000000: {
            p: 0.109,
            s: 2509929.45,
          }
        }
      },
      m: {
        deduction: 16050,
        brackets: {
          0: {
            p: 0.04,
            s: 0.0,
          },
          17150: {
            p: 0.045,
            s: 686.0,
          },
          23600: {
            p: 0.0525,
            s: 976.25,
          },
          27900: {
            p: 0.055,
            s: 1202.0,
          },
          161550: {
            p: 0.06,
            s: 8552.75,
          },
          323200: {
            p: 0.0684,
            s: 18251.75,
          },
          2155350: {
            p: 0.0965,
            s: 143754.02,
          },
          5000000: {
            p: 0.103,
            s: 418262.75,
          },
          25000000: {
            p: 0.109,
            s: 2478262.75,
          },
        },
      }
    },
  },
};
